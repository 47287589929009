import React from "react";
import { Form, Offcanvas } from "react-bootstrap";

const FormReviewQuery = ({ Show, Hide, Title }) => {
  return (
    <>
      <div>
        <Offcanvas show={Show} onHide={Hide} placement="end">
          <Offcanvas.Header>
            <Offcanvas.Title>{Title}</Offcanvas.Title>
            <div onClick={Hide} className="close_modal" title="Close">
              X
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">Comment</Form.Label>
              <Form.Control
                as={`textarea`}
                rows={8}
                placeholder="Enter Comment"
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">User Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your userId"
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your account password"
              />
            </Form.Group>
            <div className="text-end">
              <button
                class="bg-color fs-13 text-white border"
                style={{ padding: "2px 25px", borderRadius: "3px" }}
              >
                Send
              </button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default FormReviewQuery;
