import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
// import { FaRegCircleXmark } from "react-icons/fa6";
// import { IoMdCheckboxOutline } from "react-icons/io";
// import { MdOutlineComment } from "react-icons/md";
import CoverageChart from "./CoverageChart";
import FormReviewQuery from "./FormReviewQuery";
import UseToggle from "../../../Hooks/UseToggle";
import { BsFillQuestionCircleFill } from "react-icons/bs";

const FormReviewData = ({ formData }) => {
  const formStringData = formData.forms;
  const formJsonData = JSON.parse(formStringData);
  console.log("formJsonData>>>>>>>>", formJsonData);

  const { toggle, setToggle } = UseToggle();

  return (
    <>
      <div className="p-2">
        <div className={`code-list-container w-75 m-auto border rounded-1`}>
          <Container>
            <Row>
              <Form.Group
                as={Col}
                md={8}
                className="border-end overflow-auto"
                style={{ height: "calc(100vh - 173px)" }}
              >
                <div className="p-2">
                  {formJsonData?.map((item) => (
                    <div
                      className="rounded-1 position-relative mb-3"
                      // style={{ border: "1px dashed #dee2e6" }}
                    >
                      {/* <div
                          className="position-absolute rounded-1"
                          style={{ right: 15, top: -15 }}
                        >
                          <InputGroup>
                            <InputGroup.Text
                              className="py-1 px-3 fs-13 cursor-pointer bg-secondary-subtle"
                              onClick={setToggle}
                            >
                              <MdOutlineComment className="me-2" /> Query
                            </InputGroup.Text>
                            <InputGroup.Text className="py-1 px-3 fs-13 cursor-pointer bg-success-subtle">
                              <IoMdCheckboxOutline className="me-2" /> Accept
                            </InputGroup.Text>
                            <InputGroup.Text className="py-1 px-3 fs-13 cursor-pointer bg-danger-subtle">
                              <FaRegCircleXmark className="me-2" /> Reject
                            </InputGroup.Text>
                          </InputGroup>
                        </div> */}
                      {item.type === "text" && (
                        <div className="hstack gap-2">
                          <Form.Group className="mb-2 w-100">
                            <Form.Label className="mb-1 fw-bold">
                              {item.label || "Text Label"} |{" "}
                              {item.name || "Text Name"}
                              {item?.help && (
                                <BsFillQuestionCircleFill
                                  title={item?.help}
                                  className="ms-2"
                                />
                              )}
                            </Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              placeholder={item.placeholder || "Enter Label"}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2 w-100">
                            <Form.Label className="mb-1 fw-bold">
                              {item.unitLabel || "Unit Label"} |{" "}
                              {item.unitName || "Unit Name"}
                              {item?.unitHelp && (
                                <BsFillQuestionCircleFill
                                  title={item?.unitHelp}
                                  className="ms-2"
                                />
                              )}
                            </Form.Label>
                            <Form.Select disabled>
                              <option value="">Select Unit</option>
                              {item.options?.map((option) => (
                                <option value="">{option?.value}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      )}
                      {item.type === "number" && (
                        <div className="hstack gap-2">
                          <Form.Group className="mb-2 w-100">
                            <Form.Label className="mb-1 fw-bold">
                              {item.label || "Number Label"} |{" "}
                              {item.name || "Number Name"}
                              {item?.help && (
                                <BsFillQuestionCircleFill
                                  title={item?.help}
                                  className="ms-2"
                                />
                              )}
                            </Form.Label>
                            <Form.Control
                              disabled
                              type="number"
                              placeholder={item.placeholder || "Enter Label"}
                            />
                          </Form.Group>
                          <Form.Group className="mb-2 w-100">
                            <Form.Label className="mb-1 fw-bold">
                              {item.unitLabel || "Unit Label"} |{" "}
                              {item.unitName || "Unit Name"}
                              {item?.unitHelp && (
                                <BsFillQuestionCircleFill
                                  title={item?.unitHelp}
                                  className="ms-2"
                                />
                              )}
                            </Form.Label>
                            <Form.Select disabled>
                              <option value="">Select Unit</option>
                              {item.options?.map((option) => (
                                <option value="">{option?.value}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                      )}
                      {item.type === "date" && (
                        <Form.Group className="mb-2 w-100">
                          <Form.Label className="mb-1 fw-bold">
                            {item.label || "Date Label"} |{" "}
                            {item.name || "Date Name"}
                            {item?.help && (
                              <BsFillQuestionCircleFill
                                title={item?.help}
                                className="ms-2"
                              />
                            )}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            disabled
                            format={item.format}
                          />
                        </Form.Group>
                      )}
                      {item.type === "time" && (
                        <Form.Group className="mb-2 w-100">
                          <Form.Label className="mb-1 fw-bold">
                            {item.label || "Time Label"} |{" "}
                            {item.name || "Time Name"}
                            {item?.help && (
                              <BsFillQuestionCircleFill
                                title={item?.help}
                                className="ms-2"
                              />
                            )}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            disabled
                            format={item.format}
                          />
                        </Form.Group>
                      )}
                      {item.type === "datetime-local" && (
                        <Form.Group className="mb-2 w-100">
                          <Form.Label className="mb-1 fw-bold">
                            Additional Field 5
                          </Form.Label>
                          <Form.Control type="datetime-local" />
                        </Form.Group>
                      )}
                      {item.type === "checkbox" && (
                        <Form.Group className="mb-2 w-100">
                          <Form.Label className="mb-1 fw-bold">
                            {item.label || "Checkbox Label"} |{" "}
                            {item.name || "Checkbox Name"}
                            {item?.help && (
                              <BsFillQuestionCircleFill
                                title={item?.help}
                                className="ms-2"
                              />
                            )}
                          </Form.Label>
                          {item.options?.map((option) => (
                            <Form.Check
                              disabled
                              type="checkbox"
                              label={option?.value}
                            />
                          ))}
                        </Form.Group>
                      )}
                      {item.type === "radio" && (
                        <Form.Group className="mb-2 w-100">
                          <Form.Label className="mb-1 fw-bold">
                            {item.label || "Radio Label"} |{" "}
                            {item.name || "Radio Name"}
                            {item?.help && (
                              <BsFillQuestionCircleFill
                                title={item?.help}
                                className="ms-2"
                              />
                            )}
                          </Form.Label>
                          {item.options?.map((option) => (
                            <Form.Check
                              disabled
                              type="radio"
                              label={option?.value}
                            />
                          ))}
                        </Form.Group>
                      )}
                      {item.type === "textarea" && (
                        <Form.Group className="mb-2 w-100">
                          <Form.Label className="mb-1 fw-bold">
                            {item.label || "Textarea Label"} |{" "}
                            {item.name || "Textarea Name"}
                            {item?.help && (
                              <BsFillQuestionCircleFill
                                title={item?.help}
                                className="ms-2"
                              />
                            )}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            disabled
                            placeholder={item.placeholder || "Type Here..."}
                          />
                        </Form.Group>
                      )}
                      {item.type === "select" && (
                        <Form.Group className="mb-2 w-100">
                          <Form.Label className="mb-1 fw-bold">
                            {item.label || "Select Label"} |{" "}
                            {item.name || "Select Name"}
                            {item?.help && (
                              <BsFillQuestionCircleFill
                                title={item?.help}
                                className="ms-2"
                              />
                            )}
                          </Form.Label>
                          <Form.Select disabled>
                            <option value="">Select</option>
                            {item.options?.map((option) => (
                              <option value="">{option?.value}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      )}
                    </div>
                  ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} md={4} className="p-0">
                <Form.Group className="border-bottom text-center bg-light p-2">
                  <Form.Label className="fw-bold" style={{ fontSize: 14 }}>
                    Review Coverage
                  </Form.Label>
                </Form.Group>
                <div
                  style={{ overflowX: "hidden", height: "calc(100vh - 220px)" }}
                >
                  <div className="text-center">
                    <CoverageChart />
                  </div>
                  <Form.Group className="text-center p-2">
                    <Form.Label className="fw-bold">
                      Total Target : 100%
                    </Form.Label>
                  </Form.Group>
                </div>
              </Form.Group>
            </Row>
          </Container>
          <div className="p-2 border-top bg-light">
            <div className="hstack gap-2 justify-content-end">
              <button
                class="bg-secondary-subtle fs-13 border"
                style={{ padding: "2px 25px", borderRadius: "3px" }}
              >
                Cancel
              </button>
              <button
                class="bg-danger-subtle fs-13 border"
                style={{ padding: "2px 25px", borderRadius: "3px" }}
              >
                Reject
              </button>
              <button
                class="bg-color fs-13 text-white border"
                style={{ padding: "2px 25px", borderRadius: "3px" }}
                onClick={setToggle}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
      <FormReviewQuery Show={!toggle} Hide={setToggle} Title={"Add Query"} />
    </>
  );
};

export default FormReviewData;
