import React, { useEffect, useState } from "react";
import { authServices } from "../../../APIServices/authServices";
import { useParams } from "react-router-dom";
import FormReviewerTable from "./FormReviewerTable";
import { BiFilterAlt } from "react-icons/bi";

const FormReviewer = ({handleAddNewTab, setFormData}) => {
  const [loading, setLoading] = useState(false);
  const [formList, setFormList] = useState([]);
  const { studyId } = useParams();

  const getFormList = async (status) => {
    setLoading(true);
    let data = await authServices.getFormDataByStatus(studyId, "PUBLISHED,Rejected,Approved");
    setFormList(data || []);
    setLoading(false);
  };

  useEffect(() => {
    getFormList();
  }, [studyId]);

  
  return (
    <>
      <div className={`code-list-container`}>
        <div
          className="hstack justify-content-between w-100 border-bottom"
          style={{ padding: "4.6px 9px" }}
        >
          <div className="hstack gap-2">
            <button
              title="Filter"
              className="tabButtons border rounded-1 fs-13"
              style={{ padding: "1px 5px" }}
            >
              <BiFilterAlt />
            </button>
          </div>
        </div>
        <div className="position-relative p-2">
          <FormReviewerTable
            formList={formList}
            loading={loading}
            handleAddNewTab={handleAddNewTab}
            setFormData={setFormData}
          />
        </div>
      </div>
    </>
  );
};

export default FormReviewer;
