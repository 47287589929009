import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { BsFileEarmarkMedical } from "react-icons/bs";
import { authServices } from "../APIServices/authServices";
import { BiCommentDetail } from "react-icons/bi";
import FormSummary from "./Chart/FormSummary";
import QuerySummary from "./Chart/QuerySummary";

const StudyDashboard = ({tabs}) => {
  const [userDataId, setUserDataId] = useState("");
  const [userList, setUserList] = useState([]);
  const [clientID, setClientID] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [studyList, setStudyList] = useState([]);
  const [projectID, setProjectID] = useState("");
  const [studyID, setStudyID] = useState("");
  const [mode, setMode] = useState("");


  const getMyData = async () => {
    let data = await authServices.getMyData("me");
    setUserDataId(data?.principal?.clientUserName);
  };

  const getStudyData = async () => {
    let data = await authServices.getStudyData(projectID);
    setStudyList(data);
  };

  const getUserListByType = async () => {
    let data = await authServices.getUserListByType("sponsor", "createdDate", userDataId);
    setUserList(data);
  };

  const getProjectData = async () => {
    let data = await authServices.getProjectData(clientID);
    setProjectList(data);

  };

  const studyLogin = () => {
    const ProjectData = projectList.find((project) => project.id === projectID);
    if (ProjectData) {
      localStorage.setItem("ProjectData", JSON.stringify(ProjectData));
    }

    const StudyData = studyList.find((study) => study.id === studyID);
    if (StudyData) {
      localStorage.setItem("setStudyData", JSON.stringify(StudyData));
    }
    window.location.href = `/study/${studyID}`;
    localStorage.setItem("studyListId", JSON.stringify(projectID));
    localStorage.setItem("loginType", JSON.stringify("study"));
    localStorage.setItem("modeType", JSON.stringify(mode));
  };

  useEffect(() => {
    if (projectID) {
      getStudyData();
    }
  }, [projectID]);


  useEffect(() => {
    if (clientID) {
      getProjectData();
    }
  }, [clientID]);


  useEffect(() => {
    getMyData();
  }, []);

  useEffect(() => {
    if (userDataId) {
      getUserListByType();
    }
  }, [userDataId]);
  return (
    <>
      <div
        className="py-2 overflow-auto"
        style={{ height: tabs?.length ? "calc(100vh - 115px)" : "calc(100vh - 79px)" }}
      >
        <Container fluid>
          <Row>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <BsFileEarmarkMedical className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title style={{ fontSize: 20 }}>170</Card.Title>
                      <Card.Text>Total Form</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <BiCommentDetail className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title style={{ fontSize: 20 }}>150</Card.Title>
                      <Card.Text>Total Query</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            </Row>
            <Row>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body className="text-center">
                  <Card.Text>Form Summary</Card.Text>
                  <FormSummary />
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body className="text-center">
                  <Card.Text>Query Summary</Card.Text>
                  <QuerySummary />
                </Card.Body>
              </Card>
            </Form.Group>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default StudyDashboard;
