import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { BsBuildingCheck } from "react-icons/bs";
import { FaBookReader, FaFolderOpen, FaUserCheck } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { authServices } from "../APIServices/authServices";
import Clients from "./Chart/Clients";
import Users from "./Chart/Users";
import Projects from "./Chart/Projects";
import Studies from "./Chart/Studies";

const Dashboard = ({tabs}) => {
  const [userDataId, setUserDataId] = useState("");
  const [userList, setUserList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [clientID, setClientID] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [studyList, setStudyList] = useState([]);
  const [projectID, setProjectID] = useState("");
  const [studyID, setStudyID] = useState("");
  const [mode, setMode] = useState("");


  const getMyData = async () => {
    let data = await authServices.getMyData("me");
    setUserDataId(data?.principal?.clientUserName);
  };

  const getStudyData = async () => {
    let data = await authServices.getStudyData(projectID);
    setStudyList(data);
  };

  const getUserListByType = async () => {
    setLoader(true);
    let data = await authServices.getUserListByType("sponsor", "createdDate", userDataId);
    setUserList(data);
    setLoader(false);
  };

  const getProjectData = async () => {
    setLoader(true);
    let data = await authServices.getProjectData(clientID);
    setProjectList(data);
    setLoader(false);

  };

  const studyLogin = () => {
    const ProjectData = projectList.find((project) => project.id === projectID);
    if (ProjectData) {
      localStorage.setItem("ProjectData", JSON.stringify(ProjectData));
    }

    const StudyData = studyList.find((study) => study.id === studyID);
    if (StudyData) {
      localStorage.setItem("setStudyData", JSON.stringify(StudyData));
    }
    window.location.href = `/study/${studyID}`;
    localStorage.setItem("studyListId", JSON.stringify(projectID));
    localStorage.setItem("loginType", JSON.stringify("study"));
    localStorage.setItem("modeType", JSON.stringify(mode));
  };

  useEffect(() => {
    if (projectID) {
      getStudyData();
    }
  }, [projectID]);


  useEffect(() => {
    if (clientID) {
      getProjectData();
    }
  }, [clientID]);


  useEffect(() => {
    getMyData();
  }, []);

  useEffect(() => {
    if (userDataId) {
      getUserListByType();
    }
  }, [userDataId]);
  return (
    <>
      <div
        className="py-2 overflow-auto"
        style={{ height: tabs?.length ? "calc(100vh - 115px)" : "calc(100vh - 79px)" }}
      >
        <Container fluid>
          <Row>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <BsBuildingCheck className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title style={{ fontSize: 20 }}>170</Card.Title>
                      <Card.Text>Total Clients</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaUserCheck className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title style={{ fontSize: 20 }}>150</Card.Title>
                      <Card.Text>Total User</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaFolderOpen className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title style={{ fontSize: 20 }}>20</Card.Title>
                      <Card.Text>Total Project</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaBookReader className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title style={{ fontSize: 20 }}>45</Card.Title>
                      <Card.Text>Total Study</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body className="text-center">
                  <Card.Text>Client Summary</Card.Text>
                  <Clients />
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body className="text-center">
                  <Card.Text>User Summary</Card.Text>
                  <Users />
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body className="text-center">
                  <Card.Text>Project Summary</Card.Text>
                  <Projects />
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body className="text-center">
                  <Card.Text>Study Summary</Card.Text>
                  <Studies />
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Card className="mb-3 bg-light border">
                <Card.Body style={{ height: 350 }}>
                  <Card.Text className="text-center">Study Login</Card.Text>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Clients <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select onChange={(e) => setClientID(e.target.value)}>
                      <option value="">Select Clients</option>
                      {userList?.map((item, index) => (
                        <option key={index} value={item?.id}>
                          {item?.clientUserName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Project <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select onChange={(e) => setProjectID(e.target.value)}>
                      <option value="">Select Project</option>
                      {projectList?.map((item, index) => (
                        <option key={index} value={item?.id}>
                          {item?.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Study <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select onChange={(e) => setStudyID(e.target.value)}>
                      <option value="">Select Project</option>
                      {studyList?.map((item, index) => (
                        <option key={index} value={item?.id}>
                          {item?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Environment <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select onChange={(e) => setMode(e.target.value)}>
                      <option value="">Select Mode</option>
                      <option selected={mode === "DBP"} value="DBP">
                        DBP
                      </option>
                      <option selected={mode === "QC"} value="QC">
                        QC
                      </option>
                      <option selected={mode === "Live"} value="Live">
                        Live
                      </option>
                      <option selected={mode === "Study"} value="Study">
                        Study Settings
                      </option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="text-end">
                    <NavLink to={"/study"}>
                      <button onClick={studyLogin} className="p-1 px-3 border rounded-1 text-white bg-color fs-13">
                        Login
                      </button>
                    </NavLink>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Form.Group>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
