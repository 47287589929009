import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { BiFilterAlt } from "react-icons/bi";
import { authServices } from "../../../../APIServices/authServices";
import Pagination from "../../../../Hooks/Pagination";
import AddUpdateSubjectMetadata from "./AddUpdateSubjectMetadata";
import UseToggle from "../../../../Hooks/UseToggle";
import { useParams } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import Swal from "sweetalert2";

const SubjectMetadata = ({ siteId }) => {
  const [loading, setLoading] = useState(false);
  const [subjectColumnData, setSubjectColumnData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const modeType = JSON.parse(localStorage.getItem("modeType"));
  const { toggle, setToggle } = UseToggle();
  const [updateId, setUpdateId] = useState("");
  const [id, setId] = useState("");
  const { studyId } = useParams();

  const getSubjectDataById = async () => {
    setLoading(true);
    let data = await authServices.getSubjectById(siteId, modeType);
    setSubjectData(data || []);
    setLoading(false);
  };

  const getSubjectColumnData = async () => {
    setLoading(true);
    let data = await authServices.getFormEntryBySite("SUBJECT", studyId);
    setSubjectColumnData(data || []);
    setLoading(false);
  };

  const handleUpdateClick = (item) => {
    setUpdateId(item);
    setId(item?.id);
    setToggle();
  };

  const deleteSubjectMetaData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete This MetaData!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await authServices.deleteMetaData(id);
        getSubjectDataById();
      }
    });
  };

  useEffect(() => {
    getSubjectDataById();
  }, [siteId]);

  useEffect(() => {
    getSubjectColumnData();
  }, [studyId]);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = subjectData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(subjectData?.length / itemsPerPage);

  return (
    <>
      {siteId ? (
        <>
          <div
            className="hstack justify-content-between w-100 border-bottom"
            style={{ padding: "4.6px 9px" }}
          >
            <div className="hstack gap-2">
              <button
                title="Add Subject"
                className="tabButtons border rounded-1 fs-10"
                style={{ padding: "3px 10px" }}
                onClick={() => {
                  setToggle();
                  setUpdateId("");
                  setId("");
                }}
              >
                Add Subject
              </button>
              <div className="vr"></div>
              <button
                title="Filter"
                className="tabButtons border rounded-1 fs-13"
                style={{ padding: "1px 5px" }}
              >
                <BiFilterAlt />
              </button>
            </div>
          </div>
          {loading ? (
            <div
              className="hstack justify-content-center"
              style={{ height: "calc(100vh - 156px)" }}
            >
              <Spinner />
            </div>
          ) : subjectColumnData?.length === 0 ? (
            <div
              className="hstack justify-content-center"
              style={{ height: "calc(100vh - 156px)" }}
            >
              Subject Not Found!
            </div>
          ) : (
            <>
              <div
                className="position-relative px-2 mt-2 overflow-auto"
                style={{ height: "calc(100vh - 155px)" }}
              >
                <Table
                  id="resizable-table"
                  className="text-nowrap mb-1 custom-table"
                  hover
                  bordered
                  striped
                >
                  <thead className="position-sticky z-1" style={{ top: -1 }}>
                    <tr>
                      <th style={{ width: 40 }}>Sr.</th>
                      {subjectColumnData[0]?.data?.map((header, index) => (
                        <th key={index}>{header?.label}</th>
                      ))}
                      <th style={{ width: 80 }}>
                        <div className="text-center">Edit</div>
                      </th>
                      <th style={{ width: 80 }}>
                        <div className="text-center">Remove</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {result?.map((item, index) => (
                      <tr
                        key={item.id}
                        className="position-relative cursor-pointer"
                      >
                        <td>{index + 1}</td>
                        {Object.keys(item.data).map((header, i) => (
                          <td key={i}>{item.data[header]}</td>
                        ))}

                        <td
                          className="text-center"
                          onClick={() => handleUpdateClick(item)}
                        >
                          <div title="Update">
                            <FiEdit className="text-primary" />
                          </div>
                        </td>
                        <td
                          className="text-center cursor-pointer"
                          onClick={() => deleteSubjectMetaData(item.id)}
                          title="Delete Visit"
                        >
                          <i className="fa-solid fa-trash-can CP red text-danger"></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <div className="position-sticky bottom-0">
                  <Pagination
                    totalPage={totalPage}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                    totalItems={subjectData?.length}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                  />
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div
          className="hstack justify-content-center"
          style={{ height: "calc(100vh - 115px)" }}
        >
          Select for Details!
        </div>
      )}

      <AddUpdateSubjectMetadata
        Show={!toggle}
        Hide={setToggle}
        Title={`${updateId ? "Update" : "Add"} Subject`}
        getSubjectDataById={getSubjectDataById}
        subjectColumnData={subjectColumnData}
        updateId={updateId}
        siteId={siteId}
        modeType={modeType}
        id={id}
      />
    </>
  );
};

export default SubjectMetadata;
