import React, { useState } from "react";
import Moment from "react-moment";
import { Spinner, Table } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { FaRegEye } from "react-icons/fa";
import Pagination from "../../../Hooks/Pagination";
import FormAttibuteData from "./FormAttibuteData";
import UseToggle from "../../../Hooks/UseToggle";
import { authServices } from "../../../APIServices/authServices";

const FormTableData = ({
  onMouseDown,
  tableRef,
  setToggle,
  handleAddNewTab,
  loading,
  setFormID,
  formData,
  setUpdateFormData,
  formDataId,
  setFormDataId,
  getFormData,
}) => {
  const { toggle1, setToggle1 } = UseToggle();

  const viewDetail = (item) => {
    setToggle1();
    setFormDataId(item);
  };

  const UpdateForm = (item) => {
    setToggle();
    setFormID(item?.id);
    setUpdateFormData(item);
  };

  const deleteFormData = async (id) => {
    await authServices.deleteFormData(id);
    getFormData();
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  let result;
  if(formData?.length > 0){
    result = formData?.slice(startIndex, endIndex);
  }else{
    result = []
  }
  
  const totalPage = Math.ceil(formData?.length / itemsPerPage);

  return (
    <>
      {loading ? (
        <div
          className="hstack justify-content-center"
          style={{ height: "calc(100vh - 156px)" }}
        >
          <Spinner />
        </div>
      ) : result?.length === 0 ? (
        <div
          className="hstack justify-content-center"
          style={{ height: "calc(100vh - 156px)" }}
        >
          Click to view Study!
        </div>
      ) : (
        <>
          <div
            className="position-relative  overflow-auto"
            style={{ height: "calc(100vh - 164px)" }}
          >
            <Table
              tableRef={tableRef}
              id="resizable-table"
              className="text-nowrap mb-1 custom-table"
              hover
              bordered
              striped
            >
              <thead className="position-sticky z-1" style={{ top: -1 }}>
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: 40 }}
                  >
                    Sr.
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Form Name</th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>Status</th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>Form Version</th>
                  <th onMouseDown={(e) => onMouseDown(e, 4)}>
                    Modified Date | Time
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 5)}
                    style={{ width: 80 }}
                  >
                    <div className="text-center">View Form</div>
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 6)}
                    style={{ width: 80 }}
                  >
                    <div className="text-center">Update</div>
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 7)}
                    style={{ width: 60 }}
                  >
                    <div className="text-center">Remove</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr
                    key={index}
                    className={`position-relative cursor-pointer `}
                  >
                    <td onClick={() => viewDetail(item)}>
                      {index + startIndex + 1}
                    </td>
                    <td onClick={() => viewDetail(item)}>{item.name}</td>
                    <td onClick={() => viewDetail(item)}>
                      <div
                        className={item.status === "Approved" && "text-success"}
                      >
                        {item.status}
                      </div>
                    </td>
                    <td onClick={() => viewDetail(item)}>{item.version}</td>
                    <td onClick={() => viewDetail(item)}>
                      <Moment format="DD MMM YYYY | HH:mm:ss">
                        {item.modifiedOn}
                      </Moment>
                    </td>
                    <td className="text-center">
                      <div
                        title="View Form"
                        onClick={() => {
                          handleAddNewTab(
                            `${item?.name} Editor`,
                            "fa-solid fa-list-check",
                            `formEditor_${item?.id}`,
                            {
                              id: item?.id,
                            }
                          );
                          setFormDataId(item);
                        }}
                      >
                        <FaRegEye className="text-primary" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div title="Update" onClick={() => UpdateForm(item)}>
                        <FiEdit className="text-primary" />
                      </div>
                    </td>
                    <td>
                      <div
                        onClick={(e) => deleteFormData(item?.id)}
                        className="text-center cursor-pointer"
                        title="Delete Client"
                      >
                        <i
                          className={`fa-solid fa-trash-can CP red text-danger`}
                        ></i>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0">
              <Pagination
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={formData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </>
      )}

      <FormAttibuteData
        Show={!toggle1}
        Hide={setToggle1}
        Title={"View Form Detail"}
        tableRef={tableRef}
        formDataId={formDataId}
        onMouseDown={onMouseDown}
      />
    </>
  );
};

export default FormTableData;
