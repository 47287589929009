import React, { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { useParams } from "react-router-dom";
import { authServices } from "../../../../APIServices/authServices";
import { Form, Spinner, Table } from "react-bootstrap";
import { VscSaveAs } from "react-icons/vsc";
import Pagination from "../../../../Hooks/Pagination";

const SpecialForm = () => {
  const [loading, setLoading] = useState(false);
  const [specialFormData, setSpecialFormData] = useState([]);
  const [specialForm, setSpecialForm] = useState([]);
  const [visitSpecialFormData, setVisitSpecialFormData] = useState([]);
  const [order, setOrder] = useState("");
  const [indexData, setIndexData] = useState("");
  const { studyId } = useParams();

  const getSpecialFormData = async () => {
    setLoading(true);
    let data = await authServices.getSpecialFormData(studyId, "true");
    setSpecialFormData(data || []);
    setLoading(false);
  };

  const addVisitSpecialFormPosition = async (formId) => {
    const userData = {};
    userData.order = order;
    await authServices.addVisitSpecialFormPosition(userData, formId);
    getSpecialFormData();
  };

  console.log(">>specialFormData", specialFormData);

  const handleFormOrderChange = (index, value) => {
    setVisitSpecialFormData((prevData) =>
      prevData.map((item, idx) =>
        idx === index ? { ...item, formOrder: value } : item
      )
    );
  };

  useEffect(() => {
    const mainDataDict = visitSpecialFormData?.reduce((acc, item) => {
      acc[item.id] = item.logForm;
      return acc;
    }, {});

    const resultData = specialForm?.map((entry) => ({
      formId: entry.formId,
      formName: entry.label,
      logForm: mainDataDict[entry.value] || false,
      formOrder: entry?.formOrder || "",
    }));

    setVisitSpecialFormData(resultData);
  }, [specialFormData, specialForm]);

  useEffect(() => {
    setSpecialFormData(specialFormData);
  }, [specialFormData]);

  useEffect(() => {
    getSpecialFormData();
  }, [studyId]);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = specialFormData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(specialFormData?.length / itemsPerPage);

  return (
    <>
      <div className={`code-list-container`}>
        <div className="position-relative">
          {loading ? (
            <div
              className="hstack justify-content-center"
              style={{ height: "calc(100vh - 156px)" }}
            >
              <Spinner />
            </div>
          ) : specialFormData?.length === 0 ? (
            <div
              className="hstack justify-content-center"
              style={{ height: "calc(100vh - 156px)" }}
            >
              Select for set Position!
            </div>
          ) : (
            <>
              <div
                className="position-relative px-2 mt-2 overflow-auto"
                style={{ height: "calc(100vh - 157px)" }}
              >
                <Table
                  id="resizable-table"
                  className="text-nowrap mb-1 custom-table"
                  hover
                  bordered
                  striped
                >
                  <thead className="position-sticky z-1" style={{ top: -1 }}>
                    <tr>
                      <th style={{ width: 40 }}>Sr.</th>
                      <th>Form Name</th>
                      <th>Position</th>
                      <th style={{ width: 80 }}>
                        <div className="text-center">Remove</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {result?.map((item, index) => (
                      <tr
                        key={index}
                        className="position-relative cursor-pointer"
                      >
                        <td>{index + 1}</td>
                        <td>{item?.name}</td>
                        <td className="p-0">
                          <Form.Control
                            type="number"
                            placeholder="Enter Position"
                            onChange={(e) => {
                              setOrder(e.target.value);
                              setIndexData(index);
                            }}
                            value={
                              (indexData === index && order) || item?.order
                            }
                            className="py-0 border-0 bg-transparent px-2"
                          />
                        </td>
                        <td
                          className="text-center text-primary cursor-pointer"
                          title="Update / Save Position"
                          style={{ fontSize: 15 }}
                          onClick={() => addVisitSpecialFormPosition(item?.id)}
                        >
                          <VscSaveAs />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="position-sticky bottom-0">
                  <Pagination
                    totalPage={totalPage}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                    totalItems={specialFormData?.length}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SpecialForm;
